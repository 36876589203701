import React from 'react';
import styles from '../styles/SmallCardGrid.module.css';
import { SmallCardGrid as SmallCardGridProp } from "../typescript/components";
import SmallCard from './small-card';

export default function SmallCardGrid({ small_card_grid }: { small_card_grid: SmallCardGridProp }) {
    return (
        <div className={styles.root}>
            <section className={styles.bodyParagraphCardParent}>
                <div className={styles.title}>
                    {small_card_grid.title}
                </div>
                <div className={styles.spaceForAn}>
                    {small_card_grid.description}
                </div>
            </section>
            {small_card_grid.small_cards && small_card_grid.small_cards.length > 0 && (
                <section className={styles.smallSquareCardGroup}>
                    {small_card_grid.small_cards.map((card, index) => (
                        <SmallCard key={index} small_card={card} />
                    ))}
                </section>
            )}
        </div>
    );
}
