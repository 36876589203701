import React, { useState } from 'react';
import styles from "../styles/VerticalCard.module.css";
import { VerticalCard as VerticalCardProp } from "../typescript/components";

export default function VerticalCard({ vertical_card }: { vertical_card: VerticalCardProp }) {
    const [isHovered, setIsHovered] = useState(false);

    const appendWidthParam = (url: string, width: number) => {
        try {
            const urlObj = new URL(url);
            urlObj.searchParams.set('width', width.toString());
            return urlObj.toString();
        } catch (error) {
            console.error("Invalid URL:", error);
            return url;
        }
    };

    const iconSrc = isHovered && vertical_card.icon_hover && vertical_card.icon_hover.url
        ? vertical_card.icon_hover.url
        : (vertical_card.icon && vertical_card.icon.url ? vertical_card.icon.url : '');

    return (
        <div
            className={styles.verticalCard}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {vertical_card.background_image && vertical_card.background_image.url && (
                <img
                    className={styles.backgroundImage}
                    alt={vertical_card.background_image.title || 'Background Image'}
                    src={appendWidthParam(vertical_card.background_image.url, 248)}
                />
            )}
            <div className={styles.content}>
                {iconSrc && (
                    <img
                        className={styles.icon}
                        loading="lazy"
                        alt={vertical_card.icon?.title || 'Icon'}
                        src={iconSrc}
                    />
                )}
                <b className={styles.description}>{vertical_card.title}</b>
            </div>
            {isHovered && vertical_card.slogan && (
                <span className={styles.slogan}>{vertical_card.slogan}</span>
            )}
        </div>
    );
}
