import React from 'react';
import styles from '../styles/EventSpotlightBanner.module.css';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Button from "./button";
import { EventSpotlightBannerProps } from "../typescript/components";

export default function EventSpotlightBanner({ event_spotlight_banner }: { event_spotlight_banner: EventSpotlightBannerProps }) {
    const { title, subtitle, button, image } = event_spotlight_banner;
    return (
        <div className={styles.wrapper}>
            {
                image && <div className={styles.background}>
                    <img
                        className={styles.background}
                        loading="lazy"
                        alt={image.title || "CTA Banner"}
                        src={image.url}
                    />
                </div>
            }
            <div className={styles.content}>
                <div className={styles.text}>
                    {title ? <h2 className={styles.title}>{title}</h2> : <Skeleton width={100} />}
                    {subtitle ? <p className={styles.subtitle}>{subtitle}</p> : <Skeleton width={100} />}
                </div>
                {button.text ? <Button
                    text={button.text ?? button.link?.title}
                    showIcon
                    propWidth="250px"
                    propBorderColor={`${button.style === 'Solid' ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
                    propBackgroundColor={`${button.style === 'Solid' ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
                    propHoverBackgroundColor="transparent"
                    propTextColor="var(--white)"
                    propHoverText={`${button.style === 'Solid' ? `var(--charcoal-500)` : `var(--brand-primary-500)`}`}
                    link={button.link?.href}
                /> : <Skeleton width={100} />}
            </div>
        </div>
    )
}
