import React, { useState, useEffect } from 'react';
import styles from '../styles/TabContainer.module.css';
import { TabContainer as TabContainerProp } from "../typescript/components";
import ProductOverview from './product-overview';
import ProductSpecs from './product-specs';

export default function TabContainer({ tab_container }: { tab_container: TabContainerProp }) {
    return (
        <div className={styles.tabContainer}>
            <div className={styles.tabsFlexible}>
                <div className={styles.horizontalTabWrapper}>
                    <div className={styles.horizontalTab}>
                        <h4 className={styles.tab}>
                            {tab_container.overview.title}
                        </h4>
                    </div>
                    <div className={styles.horizontalTab}>
                        <h4 className={styles.tab}>
                            {tab_container.specifications.title}
                        </h4>
                    </div>
                    <div className={styles.horizontalTab}>
                        <h4 className={styles.tab}>
                            {tab_container.additional_resources.title}
                        </h4>
                    </div>
                </div>
            </div>
            <ProductOverview product_overview={tab_container.overview}></ProductOverview>
            <ProductSpecs product_specs={tab_container.specifications}></ProductSpecs>
        </div>
    );
}
