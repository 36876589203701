/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as Utils from "@contentstack/utils";
import axios, { AxiosResponse } from 'axios';
import ContentstackLivePreview from "@contentstack/live-preview-utils";
import {
  customHostUrl,
  initializeContentStackSdk,
  isValidCustomHostUrl,
} from "./utils";
import { Locale } from "../typescript/response";


type GetEntry = {
    contentTypeUid: string;
    referenceFieldPath: string[] | undefined;
    jsonRtePath: string[] | undefined;
};

type GetEntryByUrl = {
  entryUrl: string | undefined;
  contentTypeUid: string;
  referenceFieldPath: string[] | undefined;
  jsonRtePath: string[] | undefined;
};

const {
    REACT_APP_CONTENTSTACK_API_HOST,
    REACT_APP_CONTENTSTACK_API_KEY,
    REACT_APP_CONTENTSTACK_APP_HOST,
    REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
    REACT_APP_CONTENTSTACK_BRAND
} = process.env;

const customHostBaseUrl = REACT_APP_CONTENTSTACK_API_HOST? customHostUrl(
  REACT_APP_CONTENTSTACK_API_HOST as string
): "";

// SDK initialization
const Stack = initializeContentStackSdk();

// set host url only for custom host or non prod base url's
if (customHostBaseUrl && isValidCustomHostUrl(customHostBaseUrl)) {
  Stack.setHost(customHostBaseUrl);
}

// Setting LP if enabled
ContentstackLivePreview.init({
  //@ts-ignore
  stackSdk: Stack,
  clientUrlParams:{
    host: REACT_APP_CONTENTSTACK_APP_HOST
  }
})?.catch((error) => console.error(error));


export const { onEntryChange } = ContentstackLivePreview;

const renderOption = {
  span: (node: any, next: any) => next(node.children),
};

/**
 *
 * fetches all the entries from specific content-type
 * @param {* content-type uid} contentTypeUid
 * @param {* reference field name} referenceFieldPath
 * @param {* Json RTE path} jsonRtePath
 *
 */
export const getEntry = ({
    contentTypeUid,
    referenceFieldPath,
    jsonRtePath
}: GetEntry) => {
    return new Promise((resolve, reject) => {
        const query = Stack.ContentType(contentTypeUid).Query();

        if (referenceFieldPath) query.includeReference(referenceFieldPath);


        if (REACT_APP_CONTENTSTACK_BRAND) {
            console.log("Brand:", REACT_APP_CONTENTSTACK_BRAND);
            query.where("taxonomies.brand", REACT_APP_CONTENTSTACK_BRAND);
        }
        else {
            console.log("Brand:", "Not Found. Default Site.");
            query.where("taxonomies.brand", "form_technologies");
        }

        query
            .toJSON()
            .find()
            .then(
                (result) => {
                    jsonRtePath &&
                        Utils.jsonToHTML({
                            entry: result,
                            paths: jsonRtePath,
                            renderOption,
                        });
                    resolve(result);
                },
                (error) => {
                    reject(error);
                }
            );
    });
};


/**
 *fetches specific entry from a content-type
 *
 * @param {* content-type uid} contentTypeUid
 * @param {* url for entry to be fetched} entryUrl
 * @param {* reference field name} referenceFieldPath
 * @param {* Json RTE path} jsonRtePath
 * @returns
 */
export const getEntryByUrl = ({
  contentTypeUid,
  entryUrl,
  referenceFieldPath,
  jsonRtePath,
}: GetEntryByUrl) => {
  return new Promise((resolve, reject) => {
    const blogQuery = Stack.ContentType(contentTypeUid).Query();
      if (referenceFieldPath) blogQuery.includeReference(referenceFieldPath);

      if (REACT_APP_CONTENTSTACK_BRAND) {
          console.log("Brand:", REACT_APP_CONTENTSTACK_BRAND);
          blogQuery.where("taxonomies.brand", REACT_APP_CONTENTSTACK_BRAND);
      }
      else {
          console.log("Brand:", "Not Found. Default Site.");
          blogQuery.where("taxonomies.brand", "form_technologies");
      }

    blogQuery.toJSON();
    const data = blogQuery.where("url", `${entryUrl}`).find();
    data.then(
      (result) => {
        jsonRtePath &&
          Utils.jsonToHTML({
            entry: result,
            paths: jsonRtePath,
            renderOption,
          });
        resolve(result[0]);
      },
      (error) => {
        console.error(error);
        reject(error);
      }
    );
  });
};



//Try to refactor into API
const url = 'https://azure-na-api.contentstack.com/v3/locales';

interface LocalesResponse {
    locales: Locale[];
}

export const getLocales = async (): Promise<Locale[]> => {
    try {
        const response: AxiosResponse<LocalesResponse> = await axios.get(url, {
            headers: {
                'api_key': REACT_APP_CONTENTSTACK_API_KEY,
                'authorization': REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
                'Content-Type': 'application/json',
            },
        });

        // Sort locales alphabetically based on the 'name' property
        const sortedLocales = response.data.locales.sort((a, b) =>
            a.name.localeCompare(b.name)
        );

        console.log('Available Locales:', sortedLocales);

        return sortedLocales;
    } catch (error) {
        console.error('Error fetching locales:', error);

        // Optionally rethrow or return an empty array depending on how you want to handle errors
        return [];
    }
};


//// Function to update the Contentstack SDK with a new locale
//export const updateContentstackLocale = (newLocale: string) => {
//    stack.setLocale(newLocale);
//     // Update Contentstack SDK with the new locale
//    console.log(`Contentstack locale updated to ${newLocale}`);
//};