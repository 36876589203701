import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import RenderComponents from "../components/render-components";
import {getEventRes} from "../helper";
import Skeleton from "react-loading-skeleton";
import {useLivePreviewCtx} from "../context/live-preview-context-provider";
import {EventRes} from "../typescript/pages";
import {EntryProps} from "../typescript/components";

export default function Event({ entry }: { entry: ({ page }: EntryProps) => void }) {
    const lpTs = useLivePreviewCtx();
    const { eventId } = useParams();
    const history = useNavigate();
    const [getEntry, setEntry] = useState({
        event: {} as EventRes
    });
    const [error, setError] = useState(false);

    async function fetchData() {
        try {
            const entryUrl = eventId ? `/event/${eventId}` : "/";
            const event = await getEventRes(entryUrl);
            (!event) && setError(true);
            setEntry({ event });
            entry({ page: [event] });
        } catch (error) {
            console.error(error);
            setError(true);
        }
    }

    useEffect(() => {
        fetchData();
        error && history("/404");
    }, [eventId, lpTs, error]);

    const { event } = getEntry;
  return (
      <>
          {event ? (
              <RenderComponents
                  pageComponents={event.page_components}
                  blogsPage
                  contentTypeUid='event'
                  entryUid={event.uid}
                  locale={event.locale}
              />
          ) : (
              <Skeleton height={400} />
          )}

      </>
  );
}
