import React from 'react';
import styles from '../styles/Footer.module.css';
import Skeleton from 'react-loading-skeleton';
import { NavLink, Link, useMatch, useResolvedPath } from 'react-router-dom';
import { FooterRes, PageReference } from "../typescript/response";
import Button from "./button";

export default function Footer({ footer, navMenu }: { footer: FooterRes; navMenu: PageReference[] }) {
    const isFormTechnologiesTaxonomy = footer?.taxonomies && footer?.taxonomies[0]?.term_uid === "form_technologies";
    // Split the navMenu into chunks of 5
    const linkSections = [0, 1, 2].map(i => navMenu.slice(i * 5, i * 5 + 5));

    return (
        <footer>
            <div className={[styles.footer].join(" ")}>
                <section className={styles.container}>
                    <div className={styles.rightColumn}>
                        <div className={styles.logo}>
                            {footer.logo ? (
                                <img
                                    {...footer.logo.$?.url as {}}
                                    src={footer.logo.url}
                                    loading="lazy"
                                    alt={footer.logo.title}
                                    title={footer.logo.title}
                                    className={styles.brandmarkIcon}
                                />
                            ) : (
                                <Skeleton width={100} />
                            )}
                            <h5 className={styles.title}>{footer.contact_title}</h5>
                        </div>
                        <div className={styles.body}>
                            {footer.contact_description}
                        </div>
                        <div className={styles.input}>
                            <input
                                className={styles.inputContent}
                                placeholder="Email"
                                type="text"
                            />
                        </div>
                        <Button
                            text={footer.contact_submit_text}
                            showIcon
                            propWidth="250px"
                            propHeight="48px"
                            propBackgroundColor="var(--primary-500)"
                            propHoverBackgroundColor="transparent"
                            propTextColor="var(--white)"
                            link="/"
                        />
                    </div>
                    <div className={styles.linksGrid}>
                        {linkSections.map((section, sectionIndex) => (
                            <div className={styles.links} key={sectionIndex}>
                                {section.map((navItem, index) => {
                                    const resolved = useResolvedPath(navItem.page_reference[0].url);
                                    const match = useMatch({ path: resolved.pathname, end: true });
                                    return (
                                        <div className={styles.footerLink} key={index}>
                                            <NavLink
                                                {...navItem.$?.label as {}}
                                                to={navItem.page_reference[0].url}
                                                className={match ? styles.linkPrimary + ' active' : styles.linkPrimary}
                                            >
                                                {navItem.label}
                                            </NavLink>
                                        </div>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                    <div className={`${styles.social} ${isFormTechnologiesTaxonomy && styles.socialFormTechnologies}`}>
                        <span className={styles.rightColumnTitle}>{footer.right_column_title}</span>
                        <div className={styles.socialIconWrapper}>
                            {footer.social && footer.social.length > 0 ? (
                                footer.social.map((social) => (
                                    <a href={social.link.href} title={social.link.title} key={social.link.title} >
                                        <img {...social.image.$?.url as {}} src={social.image.url} alt={social.link.title} />
                                    </a>
                                ))
                            ) : (
                                <Skeleton width={100} />
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </footer>
    );
}
