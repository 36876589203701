import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Papa, { ParseResult } from 'papaparse';
import styles from '../styles/ProductSpecs.module.css';
import { ProductSpecs as ProductSpecsProp } from "../typescript/components";

export default function ProductDisplayBanner({ product_specs }: { product_specs: ProductSpecsProp }) {
    const [rows, setRows] = useState<object[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    useEffect(() => {
        if (product_specs.specification_file) {
            fetch(product_specs.specification_file.url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }
                    return response.text(); // Get the CSV content as text
                })
                .then((csvText: string) => {
                    parseCSV(csvText);
                })
                .catch(error => {
                    console.error("There was a problem with the fetch operation:", error);
                });
        }
    }, [product_specs.specification_file]);

    const parseCSV = (csvText: string) => {
        Papa.parse<{ [key: string]: string }>(csvText, {
            header: true,
            skipEmptyLines: true,
            complete: function (results: ParseResult<{ [key: string]: string }>) {
                setRows(results.data.map((row, index) => ({ id: index, ...row })));

                const firstRow = results.data[0];
                if (firstRow) {
                    const columns: GridColDef[] = Object.keys(firstRow).map(key => ({
                        field: key,
                        headerName: key,
                        width: 150, // Adjust column width as needed
                    }));
                    setColumns(columns);
                }
            },
            error: function (error: Error) { // General Error type
                console.error("Error parsing CSV:", error);
            }
        });
    };

    return (
        <div className={styles.root}>
            <section className={styles.content}>
                <h2 className={styles.overview}>{product_specs.title}</h2>
                <div style={{ height: 500, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        checkboxSelection
                    />
                </div>
            </section>
        </div>
    );
}
