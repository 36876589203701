import React, { useEffect, useMemo, useState } from 'react';
import styles from '../styles/BottomFooter.module.css';
import Skeleton from 'react-loading-skeleton';
import { BottomFooterRes } from "../typescript/response";

export default function BottomFooter({ bottomFooter }: { bottomFooter: BottomFooterRes }) {
    const [isMobile, setIsMobile] = useState(false);
    
    const getCurrentForCopyright = useMemo(()=>{
        const textYear = '{year}';
        const currentYear = new Date().getFullYear();
        return bottomFooter?.copyright && bottomFooter?.copyright?.includes(textYear) ?
                bottomFooter?.copyright?.replace(textYear,currentYear.toString()) : 
                bottomFooter?.copyright
    },[bottomFooter?.copyright]);

    const generateSocailLogoAndCopyright = useMemo(()=>{
        return (<>
            {
                bottomFooter?.message && (
                    <div className={styles.legalInfo}>
                        <div className={styles.message1}>
                            {bottomFooter.message}
                        </div>
                    </div>
                )
            }
            <div className={styles.footerBranding}>
                {bottomFooter.brand_mark_list && bottomFooter.brand_mark_list.length > 0 ? (
                    bottomFooter.brand_mark_list.map((item, index) => (
                        <a href={item?.link?.href} target="_blank" 
                            className={styles.brandmarkIcon}
                            rel="noopener noreferrer"
                            key={index}>
                            <img
                            alt={item?.logo?.title || "Brand mark"}
                            src={item?.logo?.url || "/brandmark-1.svg"}
                            />
                        </a>
                    ))
                ) : (
                    <Skeleton width={100} />
                )}
            </div>
        </>)
    },[bottomFooter])

    useEffect(()=>{
        if( window?.innerWidth<800 ){
            setIsMobile(true)
        }
    }, [])

    return (
        <div className={styles.container}>
            <div className={[styles.bottomFooter].join(" ")}>
                <div className={styles.footerMessage}>
                    <div className={styles.message}>
                        {getCurrentForCopyright}
                    </div>
                </div>
                {
                    isMobile ? 
                    (
                        generateSocailLogoAndCopyright
                    )
                    :(
                        <div className={styles['bottomFooter-right']}>
                            {generateSocailLogoAndCopyright}
                        </div>
                    )
                }
            </div>
        </div>
    );
}
