import React from 'react';
import styles from '../styles/ProductOverview.module.css';
import { ProductOverview as ProductOverviewProp } from "../typescript/components";
import GenericSmallCard from "./generic-small-card";

export default function ProductOverview({ product_overview }: { product_overview: ProductOverviewProp }) {
    return (
        <div className={styles.root}>
            <section className={styles.content}>
                <h2 className={styles.overview}>{product_overview.title}</h2>
                <div className={styles.cardGrid}>
                    <GenericSmallCard small_card={product_overview.small_card[0]} />
                    <GenericSmallCard small_card={product_overview.small_card[1]} />
                    <GenericSmallCard small_card={product_overview.small_card[2]} />
                    <GenericSmallCard small_card={product_overview.small_card[3]} />
                </div>
            </section>
            <section className={styles.loremIpsumDolorContainer}>
                <div dangerouslySetInnerHTML={{ __html: product_overview.summary }} />
            </section>
        </div>
    );
}
