import React from 'react';
import styles from "../styles/BlogCard.module.css";
import { BlogPostRes } from "../typescript/pages";

export default function BlogCard({ blog_post }: { blog_post: BlogPostRes }) {

    const appendWidthParam = (url: string, width: number) => {
        try {
            const urlObj = new URL(url);
            urlObj.searchParams.set('width', width.toString());
            return urlObj.toString();
        } catch (error) {
            console.error("Invalid URL:", error);
            return url;
        }
    };

    return (
        <div className={[styles.blogCard].join(" ")}>
            <img
                className={styles.imageIcon}
                loading="lazy"
                alt={blog_post.featured_image.title}
                src={appendWidthParam(blog_post.featured_image.url, 295)}
            />
            <div className={styles.content}>
                <div className={styles.copy}>
                    <b className={styles.onDemand}>{blog_post.title}</b>
                    <p className={styles.precisionInProduction}>
                        {blog_post.description}
                    </p>
                </div>
            </div>
        </div>
    );
}
