import React from 'react';
import styles from '../styles/LocationOverview.module.css';
import { LocationOverview as LocationOverviewProp } from "../typescript/components";

export default function AboutBanner({ location_overview }: { location_overview: LocationOverviewProp }) {
    const isVideo = (filename: string) => {
        return /\.(mp4|webm|ogg)$/i.test(filename);
    };

    const appendWidthParam = (url: string, width: number) => {
        try {
            const urlObj = new URL(url);
            urlObj.searchParams.set('width', width.toString());
            return urlObj.toString();
        } catch (error) {
            console.error("Invalid URL:", error);
            return url;
        }
    };

    return (
        <div className={styles.locationsWrapper}>
            <div className={styles.root}>
                <section className={styles.locationsOptions}>
                    <div className={styles.categoryOptions}>
                        <div className={styles.category}>
                            <div className={styles.categoryChild} />
                            <a className={styles.filters}>FILTERS</a>
                            <img className={styles.categoryItem} alt="" src="/line-6.svg" />
                            <div className={styles.loadOptions} />
                        </div>
                        <div className={styles.clearAllFiltersButton}>
                            <a className={styles.clearAllFilters}>Clear All Filters</a>
                        </div>
                    </div>
                    <div className={styles.filterOptions}>
                        <div className={styles.locationFilter}>
                            <div className={styles.locationOptions}>
                                <div className={styles.locationName}>
                                    <div className={styles.locations}>Locations</div>
                                </div>
                                <div className={styles.locationSelect}>
                                    <div className={styles.locationSelectOptions}>
                                        <div className={styles.selectBox}>
                                            <img
                                                className={styles.contractIcon}
                                                loading="lazy"
                                                alt=""
                                                src="/contract.svg"
                                            />
                                        </div>
                                        <div className={styles.northAmerica}>North America</div>
                                    </div>
                                    <div className={styles.locationSelectOptions1}>
                                        <input className={styles.selectBox1} type="checkbox" />
                                        <div className={styles.canada}>Canada</div>
                                    </div>
                                    <div className={styles.locationSelectOptions2}>
                                        <input className={styles.selectBox2} type="checkbox" />
                                        <div className={styles.mexico}>Mexico</div>
                                    </div>
                                    <div className={styles.locationSelectOptions3}>
                                        <input className={styles.selectBox3} type="checkbox" />
                                        <div className={styles.unitedStates}>United States</div>
                                    </div>
                                    <div className={styles.locationSelectOptions4}>
                                        <img
                                            className={styles.expandIcon}
                                            loading="lazy"
                                            alt=""
                                            src="/expand.svg"
                                        />
                                        <div className={styles.europe}>Europe</div>
                                    </div>
                                    <div className={styles.locationSelectOptions5}>
                                        <img
                                            className={styles.expandIcon1}
                                            loading="lazy"
                                            alt=""
                                            src="/expand-1.svg"
                                        />
                                        <div className={styles.asia}>Asia</div>
                                    </div>
                                    <div className={styles.locationSelectOptions6}>
                                        <img
                                            className={styles.expandIcon2}
                                            loading="lazy"
                                            alt=""
                                            src="/expand-2.svg"
                                        />
                                        <div className={styles.africa}>Africa</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.certificationSelect}>
                                <div className={styles.certifications}>
                                    <div className={styles.certifications1}>Certifications</div>
                                </div>
                                <div className={styles.certificationSelectOptions}>
                                    <div className={styles.certificationSelectItems}>
                                        <input
                                            className={styles.selectBoxUnselected}
                                            type="checkbox"
                                        />
                                        <div className={styles.iatf169492016}>IATF 16949:2016</div>
                                    </div>
                                </div>
                                <div className={styles.certificationSelectOptions1}>
                                    <div className={styles.selectBoxUnselectedParent}>
                                        <input
                                            className={styles.selectBoxUnselected1}
                                            type="checkbox"
                                        />
                                        <div className={styles.iso90012015}>ISO 9001:2015</div>
                                    </div>
                                </div>
                                <div className={styles.certificationSelectOptions2}>
                                    <div className={styles.selectBoxUnselectedGroup}>
                                        <input
                                            className={styles.selectBoxUnselected2}
                                            type="checkbox"
                                        />
                                        <div className={styles.iso140012015}>ISO 14001:2015</div>
                                    </div>
                                </div>
                                <div className={styles.certificationSelectOptions3}>
                                    <div className={styles.selectBoxUnselectedContainer}>
                                        <input
                                            className={styles.selectBoxUnselected3}
                                            type="checkbox"
                                        />
                                        <div className={styles.other}>OTHER</div>
                                    </div>
                                </div>
                                <div className={styles.certificationSelectOptions4}>
                                    <div className={styles.frameDiv}>
                                        <input
                                            className={styles.selectBoxUnselected4}
                                            type="checkbox"
                                        />
                                        <div className={styles.iso450012018}>ISO 45001:2018</div>
                                    </div>
                                </div>
                                <div className={styles.certificationSelectOptions5}>
                                    <div className={styles.selectBoxUnselectedParent1}>
                                        <input
                                            className={styles.selectBoxUnselected5}
                                            type="checkbox"
                                        />
                                        <div className={styles.ohsas180012007}>OHSAS 18001:2007</div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.metalsSelect}>
                                <div className={styles.metals}>
                                    <div className={styles.metals1}>Metals</div>
                                </div>
                                <div className={styles.metalsSelectOptions}>
                                    <div className={styles.metalsSelectItems}>
                                        <input
                                            className={styles.selectBoxUnselected6}
                                            type="checkbox"
                                        />
                                        <div className={styles.aluminum}>aluminum</div>
                                    </div>
                                </div>
                                <div className={styles.metalsSelectOptions1}>
                                    <div className={styles.selectBoxUnselectedParent2}>
                                        <input
                                            className={styles.selectBoxUnselected7}
                                            type="checkbox"
                                        />
                                        <div className={styles.magnesium}>Magnesium</div>
                                    </div>
                                </div>
                                <div className={styles.metalsSelectOptions2}>
                                    <div className={styles.selectBoxUnselectedParent3}>
                                        <input
                                            className={styles.selectBoxUnselected8}
                                            type="checkbox"
                                        />
                                        <div className={styles.zincAndZamac}>Zinc and Zamac</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <button className={styles.taxonomyLabel}>
                    <div className={styles.applyFilters}>Apply Filters</div>
                </button>
            </div>
            <div className={styles.frameParent}>
                <section className={styles.rectangleParent}>
                    <div className={styles.frameChild} />
                    <div className={styles.map}>Map</div>
                </section>
                <section className={styles.frameGroup}>
                    <div className={styles.northAmericaParent}>
                        <h3 className={styles.northAmerica}>North America</h3>
                        <div className={styles.frameItem} />
                        <div className={styles.canada}>Canada</div>
                        <div className={styles.dyncastMontreal}>{`Dyncast Montreal >`}</div>
                        <div
                            className={styles.dynacastPeterborough}
                        >{`Dynacast Peterborough >`}</div>
                        <div className={styles.mexico}>Mexico</div>
                        <div
                            className={styles.dyncastMexicoCity}
                        >{`Dyncast Mexico City >`}</div>
                        <div className={styles.unitedStates}>United States</div>
                        <div
                            className={styles.dyncastLakeForest}
                        >{`Dyncast Lake Forest >`}</div>
                        <div
                            className={styles.dyncastGermantown}
                        >{`Dyncast Germantown >`}</div>
                        <div className={styles.dyncastEligin}>{`Dyncast Eligin >`}</div>
                    </div>
                    <div className={styles.europeParent}>
                        <h3 className={styles.europe}>Europe</h3>
                        <div className={styles.frameInner} />
                        <div className={styles.austria}>Austria</div>
                        <div className={styles.dyncastAustria}>{`Dyncast Austria >`}</div>
                        <div className={styles.france}>France</div>
                        <div className={styles.dyncastFrance}>{`Dyncast France >`}</div>
                        <div className={styles.germany}>Germany</div>
                        <div
                            className={styles.bonerGalvanotechnikPlating}
                        >{`BONER Galvanotechnik Plating >`}</div>
                        <div
                            className={styles.dyncastGermany}
                        >{`Dyncast Germany >`}</div>
                        <div className={styles.italy}>Italy</div>
                        <div className={styles.dynacastTurin}>{`Dynacast Turin >`}</div>
                        <div className={styles.dynacastMilan}>{`Dynacast Milan >`}</div>
                        <div className={styles.spain}>Spain</div>
                        <div className={styles.dyncastSpain}>{`Dyncast Spain >`}</div>
                        <div className={styles.unitedKingdom}>United Kingdom</div>
                        <div className={styles.dyncastUk}>{`Dyncast UK >`}</div>
                    </div>
                    <div className={styles.asiaParent}>
                        <h3 className={styles.asia}>Asia</h3>
                        <div className={styles.lineDiv} />
                        <div className={styles.china}>China</div>
                        <div className={styles.dyncastSuzhou}>{`Dyncast Suzhou >`}</div>
                        <div className={styles.dynacastDongguan}>{`Dynacast Dongguan >`}</div>
                        <div className={styles.india}>India</div>
                        <div
                            className={styles.sundaramDyncastIndia}
                        >{`Sundaram Dyncast India >`}</div>
                        <div className={styles.indonesia}>Indonesia</div>
                        <div className={styles.dyncastIndonesia}>{`Dyncast Indonesia >`}</div>
                        <div className={styles.japan}>Japan</div>
                        <div className={styles.dyncastTokyoRegionalContainer}>
                            <span>
                                <p className={styles.dyncastTokyo}>{`Dyncast Tokyo `}</p>
                                <p
                                    className={styles.regionalSupportOffice}
                                >{`Regional Support Office >`}</p>
                            </span>
                        </div>
                        <div className={styles.dyncastNagoyaRegionalContainer}>
                            <span>
                                <p className={styles.dyncastNagoya}>Dyncast Nagoya</p>
                                <p
                                    className={styles.regionalSupportOffice1}
                                >{`Regional Support Office >`}</p>
                            </span>
                        </div>
                        <div className={styles.malaysia}>Malaysia</div>
                        <div className={styles.dyncastMalaysia}>{`Dyncast Malaysia >`}</div>
                        <div className={styles.singapore}>Singapore</div>
                        <div
                            className={styles.dyncastSingarpore}
                        >{`Dyncast Singarpore >`}</div>
                        <div className={styles.southKorea}>South Korea</div>
                        <div className={styles.dyncastKoreaRegionalContainer}>
                            <span>
                                <p className={styles.dyncastKorea}>Dyncast Korea</p>
                                <p
                                    className={styles.regionalSupportOffice2}
                                >{`Regional Support Office >`}</p>
                            </span>
                        </div>
                        <div className={styles.thailand}>Thailand</div>
                        <div className={styles.dyncastThailandRegionalContainer}>
                            <span>
                                <p className={styles.dyncastThailand}>Dyncast Thailand</p>
                                <p
                                    className={styles.regionalSupportOffice3}
                                >{`Regional Support Office >`}</p>
                            </span>
                        </div>
                        <div className={styles.vietnam}>Vietnam</div>
                        <div className={styles.dynacastVietnamSales}>
                            Dynacast Vietnam (Sales Office)
                        </div>
                    </div>
                    <div className={styles.africaParent}>
                        <h3 className={styles.africa}>Africa</h3>
                        <div className={styles.frameChild1} />
                        <div className={styles.tunisia}>Tunisia</div>
                        <div className={styles.dyncastTunisia}>{`Dyncast Tunisia >`}</div>
                    </div>
                </section>
            </div>
        </div>
    );
}
