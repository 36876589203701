import { getEntry, getEntryByUrl } from "../sdk/entry";
import { addEditableTags } from "@contentstack/utils";
import { BottomFooterRes, FooterRes, HeaderRes } from "../typescript/response";
import { BlogPostRes, Page, ProductRes, ArticleRes, EventRes } from "../typescript/pages";

const liveEdit = process.env.REACT_APP_CONTENTSTACK_LIVE_EDIT_TAGS === "true";

export const getHeaderRes = async (): Promise<HeaderRes> => {
    const response = (await getEntry({
        contentTypeUid: "header",
        referenceFieldPath: ["navigation_menu.page_reference", "navigation_menu.sub_navigation.page_reference", "locations.page_reference", "search_page", "request_quote.page_reference", "contact_us.page_reference"],        jsonRtePath: ["notification_bar.announcement_text"]
    })) as HeaderRes[][];

    console.log("Header Response:", response); // Log the entire response to inspect the structure
    const headerRes = response[0][0];

    console.log("Header Res:", headerRes); // Log the headerRes to check if `locations` is included

    liveEdit && addEditableTags(headerRes, "header", true);

    return headerRes;
};

export const getFooterRes = async (): Promise<FooterRes> => {
  const response = (await getEntry({
      contentTypeUid: "footer",
      referenceFieldPath: ["navigation_menu.page_reference"],
      jsonRtePath: ["copyright"]
  })) as FooterRes[][];

  liveEdit && addEditableTags(response[0][0], "footer", true);

  return response[0][0];
};

export const getBottomFooterRes = async (): Promise<BottomFooterRes> => {
    const response = (await getEntry({
        contentTypeUid: "bottom_footer",
        jsonRtePath: ["copyright"],
        referenceFieldPath: undefined
    })) as BottomFooterRes[][];
    liveEdit && addEditableTags(response[0][0], "bottom_footer", true);
    return response[0][0];
};

export const getAllEntries = async (): Promise<Page[]> => {
  const response = (await getEntry({
    contentTypeUid: "page",
    jsonRtePath: undefined,
      referenceFieldPath: undefined
  })) as Page[][];
  liveEdit &&
    response[0].forEach((entry) => addEditableTags(entry, "blog_post", true));
  return response[0];
};

export const getPageRes = async (entryUrl: string): Promise<Page> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "page",
    entryUrl,
    referenceFieldPath: ["page_components.blog_banner.featured_blogs",
          "page_components.resource_grid.featured_resources"],
    jsonRtePath: [
        "page_components.from_blog.featured_blogs.body",
        "page_components.resource_grid.featured_resources.resource"
    ],
  })) as Page[];
    liveEdit && addEditableTags(response[0], "page", true);

    console.log("Page Response:", response); // Log the entire response to inspect the structure

  return response[0];
};

export const getProductRes = async (entryUrl: string): Promise<ProductRes> => {
    const response = (await getEntryByUrl({
        contentTypeUid: "product",
        entryUrl,
        referenceFieldPath: [],
        jsonRtePath: [],
    })) as ProductRes[];
    liveEdit && addEditableTags(response[0], "product", true);

    console.log("Product Response:", response); // Log the entire response to inspect the structure

    return response[0];
};

export const getArticleRes = async (entryUrl: string): Promise<ArticleRes> => {
    const response = (await getEntryByUrl({
        contentTypeUid: "article",
        entryUrl,
        referenceFieldPath: ["page_components.resource_grid.featured_resources"],
        jsonRtePath: [
            "page_components.from_blog.featured_blogs.body",
            "page_components.resource_grid.featured_resources.resource"
        ],
    })) as ArticleRes[];
    liveEdit && addEditableTags(response[0], "article", true);

    console.log("Article Response:", response); // Log the entire response to inspect the structure

    return response[0];
};

export const getEventRes = async (entryUrl: string): Promise<EventRes> => {
    const response = (await getEntryByUrl({
        contentTypeUid: "event",
        entryUrl,
        referenceFieldPath: ["page_components.resource_grid.featured_resources"],
        jsonRtePath: [
            "page_components.from_blog.featured_blogs.body",
            "page_components.resource_grid.featured_resources.resource"
        ],
    })) as EventRes[];
    liveEdit && addEditableTags(response[0], "event", true);

    console.log("Event Response:", response); // Log the entire response to inspect the structure

    return response[0];
};

export const getBlogListRes = async (): Promise<{
  archivedBlogs: BlogPostRes[];
  recentBlogs: BlogPostRes[];
}> => {
  const response = (await getEntry({
    contentTypeUid: "blog_post",
    referenceFieldPath: ["author", "related_post"],
    jsonRtePath: ["body"],
  })) as BlogPostRes[][];
  liveEdit &&
    response[0].forEach((entry) => addEditableTags(entry, "blog_post", true));
  const archivedBlogs = [] as BlogPostRes[];
  const recentBlogs = [] as BlogPostRes[];

  response[0].forEach((blogs) => {
    if (blogs.is_archived) {
      archivedBlogs.push(blogs);
    } else {
      recentBlogs.push(blogs);
    }
  });
  return { archivedBlogs, recentBlogs };
};

export const getBlogPostRes = async (
  entryUrl: string
): Promise<BlogPostRes> => {
  const response = (await getEntryByUrl({
    contentTypeUid: "blog_post",
    entryUrl,
    referenceFieldPath: ["author", "related_post"],
    jsonRtePath: ["body", "related_post.body"],
  })) as BlogPostRes[];
  liveEdit && addEditableTags(response[0], "blog_post", true);
  return response[0];
};
