import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import RenderComponents from "../components/render-components";
import { getProductRes } from "../helper";
import Skeleton from "react-loading-skeleton";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { ProductRes } from "../typescript/pages";
import ProductDisplayBanner from "../components/product-display-banner"
import TabContainer from "../components/tab-container"
import { EntryProps } from "../typescript/components";

export default function Product({entry}:{entry:({page}:EntryProps)=> void}) {
  const lpTs = useLivePreviewCtx();
  const { productId } = useParams();
  const history = useNavigate();
  const [getEntry, setEntry] = useState({
      product: {} as ProductRes
  });
  const [error, setError] = useState(false);

  async function fetchData() {
    try {
        const entryUrl = productId ? `/product/${productId}` : "/";
        const product = await getProductRes(entryUrl);
        (!product) && setError(true);
        setEntry({ product });
        entry({ page: [product] });
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }

  useEffect(() => {
    fetchData();
    error && history("/404");
  }, [productId, lpTs, error]);

    const { product } = getEntry;
  return (
    <>
          {product.product_display_banner ? (<ProductDisplayBanner product_display_banner={product.product_display_banner}></ProductDisplayBanner>) : (
              <Skeleton height={400} />
          )}

          {product.tab_container ? (<TabContainer tab_container={product.tab_container}></TabContainer>) : (
              <Skeleton height={200} />
          )}

          {product ? (
            <RenderComponents
                pageComponents={product.page_components}
                blogsPage
                contentTypeUid='product'
                entryUid={product.uid}
                locale={product.locale}
            />
          ) : (
            <Skeleton height={400} />
          )}

    </>
  );
}
