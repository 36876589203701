import React from 'react';
import styles from '../styles/ResourceGrid.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Skeleton from 'react-loading-skeleton';
import { ResourceGrid as ResourceGridProp } from "../typescript/components";
import { Swiper as SwiperType } from 'swiper';
import BlogCard from './blog-card';
import { BlogPostRes } from "../typescript/pages";
import Button from "./button";

export default function ResourceGrid({ resource_grid }: { resource_grid: ResourceGridProp }) {
    return (
        <div className={styles.resourceGrid}>
            <section className={styles.container}>
                <div className={styles.content}>
                    <h1 className={styles.alloysLoremIpsum}>{resource_grid.title}</h1>
                    <div className={styles.sentencesAboutCompany}>
                        {resource_grid.description}
                    </div>
                    {resource_grid.cta ? (
                        <Button
                            text={resource_grid.cta.cta_title}
                            showIcon
                            propWidth="250px"
                            propBackgroundColor="var(--primary-500)"
                            propHoverBackgroundColor="transparent"
                            propTextColor="var(--white)"
                            link={resource_grid.cta.cta_link.url}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <div className={styles.grid}>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        loop={true}
                        spaceBetween={2}
                        slidesPerView={3}
                        navigation={{
                            nextEl: `.${styles.nextButton}`,
                            prevEl: `.${styles.prevButton}`
                        }}
                        pagination={{
                            el: `.${styles.pagination}`,
                            clickable: true,
                        }}
                        className={styles.slider}
                    >
                        {resource_grid.featured_resources.length > 0 ? (
                            resource_grid.featured_resources.map((item, index) => (
                                <SwiperSlide key={index} className={styles.slide}>
                                    <BlogCard blog_post={item as BlogPostRes} />
                                </SwiperSlide>
                            ))
                        ) : (
                            Array.from({ length: 4 }).map((_, index) => (
                                <SwiperSlide key={index} className={styles.slide}>
                                    <Skeleton count={1} className={styles.logo} />
                                </SwiperSlide>
                            ))
                        )}
                    </Swiper>
                </div>
            </section>
            <div className={styles.navigation}>
                <button className={styles.prevButton}>
                    <img
                        className={styles.chevronLeftLarge}
                        loading="lazy"
                        alt=""
                        src="/chevron-left-large.svg"
                    />
                </button>
                <div className={styles.paginationButton}>
                    <div className={styles.pagination}></div>
                </div>
                <button className={styles.nextButton}>
                    <img
                        className={styles.chevronRightLarge}
                        loading="lazy"
                        alt=""
                        src="/chevron-right-large.svg"
                    />
                </button>
            </div>
        </div>
    );
}
