import React, { useState, CSSProperties, useEffect } from 'react';
import styles from '../styles/Header.module.css';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { HeaderRes, NavigationMenu } from "../typescript/response";
import MegaNav from "./mega-nav";
import LanguageSelector from "./language-selector";
import SearchBar from "./search-bar";
import Helmet from "react-helmet";

export default function Header({ header, navMenu }: { header: HeaderRes, navMenu: NavigationMenu[] }) {

    const [isHovered, setIsHovered] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);

    // Handle locale change
    const handleLocaleChange = (newLocale: string) => {
        console.log('Locale changed to:', newLocale);
    };

    // Toggle the navigation menu state
    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
        if (!isNavOpen) {
            setIsSearchOpen(false); // Close search if the navigation is opened
        }
    };

    // Toggle the search bar state
    const toggleSearch = () => {
        setIsSearchOpen(!isSearchOpen);
        if (!isSearchOpen) {
            setIsNavOpen(false); // Close navigation if the search is opened
        }
    };

    const [isOpenMobileSideBar, setIsOpenMobileSideBar] = useState(false);
    useEffect(() => {
        const hide = () => document.body.style.overflow = 'hidden'
        const show = () => document.body.style.overflow = 'auto'

        const handleResize = () => {
            window.innerWidth >= 1200 ? show() : (isOpenMobileSideBar ? hide() : show()) ;
        };

        if (isOpenMobileSideBar) {
            hide()
        } else if (window.innerWidth < 1200) {
            show()
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isOpenMobileSideBar]);
    const toggleSidebar = () => {
        setIsOpenMobileSideBar(!isOpenMobileSideBar);
    };
    const halfPill: CSSProperties = {
        backgroundColor: isHovered ? 'transparent' : 'var(--primary-500)',
        color: isHovered ? ('var(--primary-500)') : ('var(--white)'),
        border: '1px solid var(--primary-500)',
        borderRadius: 'var(--br-base) 0 0 var(--br-base)',
        boxSizing: 'border-box',
        cursor: 'pointer',
        transition: 'background-color 300ms ease-out, color 300ms ease-out, box-shadow 300ms ease-out',
        textAlign: 'center', // Center the text
        textDecoration: 'none', // Ensure no underline
        flex: '1',
        lineHeight: '20px',
        textDecorationLine: 'none',
        position: 'relative',
        textTransform: 'capitalize',
        justifyContent: 'center',
        padding: '3.5px var(--xs)',
        fontSize: 'var(--small-size)',
        width: '102px'
    };

    const appendHeightParam = (url: string, height: number) => {
        try {
            const urlObj = new URL(url);
            urlObj.searchParams.set('height', height.toString());
            return urlObj.toString();
        } catch (error) {
            console.error("Invalid URL:", error);
            return url;
        }
    };

    return (
        <header className='content'>
            <Helmet>
                {header.metadata && header.metadata.site_title && (
                    <title>{header.metadata.site_title}</title>
                )}

                {header.metadata && header.metadata.fav_icon ? (
                    <link rel="icon" href={header.metadata.fav_icon.url} type="image/x-icon" />
                ) : ''}
            </Helmet>
            <div className={styles.navWrap}>
                <div className={styles.container}>
                    <div className={styles.logoDark}>
                        {header.logo ? (
                            <Link to='/' title=''>
                                <img
                                    {...(header.logo.$?.url as {})}
                                    className='logo'
                                    loading="lazy"
                                    alt={header.logo.filename}
                                    src={appendHeightParam(header.logo.url, 55)}
                                />
                            </Link>
                        ) : (
                            <a>
                                <Skeleton width={200} />
                            </a>
                        )}
                    </div>
                    <MegaNav navMenu={header.navigation_menu}
                        isMobile={false}
                        isOpenMobileSideBar={false}
                        handleCloseSideBar={setIsOpenMobileSideBar}
                        languages={header.languages}
                        currentLocale={header.currentLocale}
                        onLocaleChange={handleLocaleChange}
                        locations={header.locations}
                    ></MegaNav>
                    <div className={styles.sideNav}>
                        <div className={styles.top}>
                            {header?.search_page?.[0]?.url && (
                                <SearchBar search_page={header.search_page[0].url}
                                search_placeholder={header.search_placeholder}></SearchBar>
                            )}
                            {header?.locations?.page_reference?.[0]?.url && (
                                <a href={header.locations.page_reference[0].url} className={styles.locations}>
                                    {header.locations.label}
                                </a>
                            )}
                            <LanguageSelector
                                languages={header.languages}
                                currentLocale={header.currentLocale}
                                onLocaleChange={handleLocaleChange}  // Provide the onLocaleChange handler
                            />
                        </div>
                        <div className={styles.bottom}>
                            {header.request_quote && header.request_quote.page_reference[0] && header.request_quote.page_reference[0].url && (
                                <a className={styles.requestAQuote} href={header.request_quote.page_reference[0].url}>{header.request_quote.label ?? 'Request a Quote'}</a>
                            )}

                            {header.contact_us && header.contact_us.page_reference && (
                                <a style={halfPill}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                    href={header.contact_us.page_reference[0].url}>{header.contact_us.label ?? 'Contact'}</a>
                            )}
                        </div>
                    </div>
                    <div className={styles.hamburgerMenu}>
            
                        {header?.search_page?.[0]?.url && (
                            <SearchBar
                                search_page={header.search_page[0].url}
                                isVisible={isSearchOpen}
                                search_placeholder={header.search_placeholder}
                                toggleVisibility={toggleSearch} />
                        )}
                        {isNavOpen ? (
                            <img
                                onClick={toggleNav}
                                className={styles.closeMenuIcon}
                                loading="lazy"
                                alt="Menu"
                                src="/closex.svg"
                            />
                        ) : (
                            <img
                                onClick={toggleNav}
                                className={styles.hamburgerMenuIcon}
                                loading="lazy"
                                alt="Menu"
                                src="/hamburger.svg"
                            />
                        )}
                        <MegaNav
                            navMenu={header.navigation_menu}
                            isMobile={true}
                            isOpenMobileSideBar={isNavOpen}
                            handleCloseSideBar={() => setIsNavOpen(false)}
                            languages={header.languages}
                            currentLocale={header.currentLocale}
                            onLocaleChange={handleLocaleChange}
                            locations={header.locations}
                        />                        
                    </div>
                </div>
            </div>
        </header>
    );
}
