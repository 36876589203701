import React from 'react';
import styles from '../styles/NotificationBar.module.css';
import { NotificationBar as NotificationBarProp } from "../typescript/components";
import parse, { domToReact, HTMLReactParserOptions, Element } from 'html-react-parser';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

export default function NotificationBar({ notification_bar }: { notification_bar: NotificationBarProp }) {
    // Function to strip out <p> tags
    const stripPTags = (htmlString: string) => {
        const options: HTMLReactParserOptions = {
            replace: (node) => {
                if (node instanceof Element && node.name === 'p') {
                    console.log("Stripping <p> tag:", node); // Debug log to check if <p> tags are found
                    return <>{domToReact(node.children, options)}</>;
                }
            },
        };
        return parse(htmlString, options);
    };

    // Check if notification_bar is defined and has keys
    const hasNotification = notification_bar && Object.keys(notification_bar).length > 0;

    return (
        <>
            {hasNotification ? (
                <div className={styles.announcementBanner} {...(notification_bar.$?.announcement_text as {})}>
                    {notification_bar.notification_type && ( // Check if notification_type is set
                        <div className={styles.type}>{notification_bar.notification_type}:</div>
                    )}
                    <div className={styles.message}>
                        {notification_bar.show_announcement && notification_bar.announcement_text &&
                            stripPTags(notification_bar.announcement_text)}
                    </div>
                    {notification_bar?.cta_link.href &&
                        <Link
                            to={notification_bar?.cta_link.href ? notification_bar?.cta_link.href : "/"}
                            className={styles.cta_announcement}
                        >
                            {notification_bar?.cta_link?.title}
                        </Link>
                    }
                </div>
            ) : (
                <div className={styles.announcementBanner}>
                    <Skeleton />
                </div>
            )}
        </>
    );
}
