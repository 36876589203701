import React, { useState, useEffect } from 'react';
import styles from '../styles/ProductDisplayBanner.module.css';
import { ProductDisplayBanner as ProductDisplayBannerProp } from "../typescript/components";
import { Img } from "../typescript/pages";
import Button from "./button";

export default function ProductDisplayBanner({ product_display_banner }: { product_display_banner: ProductDisplayBannerProp }) {
    const mediaAssets = product_display_banner.media_assets || []; // Ensure media_assets is not null/undefined
    const [selectedMedia, setSelectedMedia] = useState<Img | null>(mediaAssets[0] || null);
    const [thumbnails, setThumbnails] = useState<{ [key: string]: string }>({});
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    // Function to extract a thumbnail from a video
    const generateThumbnail = (videoUrl: string) => {
        return new Promise<string>((resolve, reject) => {
            const video = document.createElement('video');
            video.src = videoUrl;
            video.crossOrigin = 'anonymous'; // Ensure cross-origin access
            video.addEventListener('loadeddata', () => {
                video.currentTime = 1; // Seek to a specific time
            });
            video.addEventListener('seeked', () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(video, 0, 0);
                    resolve(canvas.toDataURL()); // Get the base64 image URL
                } else {
                    reject(new Error('Unable to get canvas context'));
                }
            });
        });
    };

    // Generate thumbnails for video assets when component mounts
    useEffect(() => {
        const generateThumbnails = async () => {
            const newThumbnails: { [key: string]: string } = {};
            for (const media of mediaAssets) {
                if (media.content_type === 'video/mp4') {
                    try {
                        newThumbnails[media.url] = await generateThumbnail(media.url);
                    } catch (error) {
                        console.error('Error generating thumbnail:', error);
                    }
                }
            }
            setThumbnails(newThumbnails);
        };
        generateThumbnails();
    }, [mediaAssets]);

    const handleMediaClick = (media: Img) => {
        setSelectedMedia(media);
        if (media.content_type === 'video/mp4') {
            setIsPlaying(true);
        }
    };

    const handleVideoClick = () => {
        if (selectedMedia && selectedMedia.content_type === 'video/mp4') {
            setIsPlaying(true);
        }
    };

    const isVideo = (media: Img | null) => media?.content_type === 'video/mp4';

    return (
        <div className={styles.productDisplayHero}>
            <section className={styles.container}>
                <div className={styles.content}>
                    <h2 className={styles.productFamily}>{product_display_banner.title}</h2>
                    <div className={styles.divider} />
                    <div className={styles.description}>
                        {product_display_banner.description}
                    </div>
                    <div className={styles.buttonGroup}>
                        <Button
                            text={product_display_banner.cta.cta_title}
                            showIcon={false}
                            propWidth="250px"
                            propBackgroundColor="var(--primary-500)"
                            propHoverBackgroundColor="transparent"
                            propTextColor="var(--white)"
                            propAlignSelf="stretch"
                            link={product_display_banner.cta.cta_link.url}
                        />
                        <Button
                            text={product_display_banner.download.cta_title}
                            showIcon={false}
                            propWidth="250px"
                            propTextColor="var(--primary-500)"
                            propBackgroundColor="transparent"
                            propAlignSelf="stretch"
                            link={product_display_banner.download.cta_link.url}
                        />
                    </div>
                </div>
                {mediaAssets.length > 0 && (
                    <div className={styles.videoGrid}>
                        {selectedMedia && isVideo(selectedMedia) ? (
                            <div className={styles.videoWrapper} onClick={handleVideoClick}>
                                <video
                                    className={`${styles.largeVideoIcon} ${isPlaying ? styles.playing : ''}`}
                                    controls
                                    autoPlay={isPlaying}
                                    aria-label="Selected video"
                                    onClick={e => e.stopPropagation()} // Prevent click on video from triggering parent div click
                                >
                                    <source src={selectedMedia.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                {!isPlaying && (
                                    <div className={styles.playButtonWrapper} onClick={handleVideoClick}>
                                        <img src="/play-button.svg" alt="Play" className={styles.playButton} />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <img
                                className={`${styles.largeVideoIcon} ${isPlaying ? styles.playing : ''}`}
                                loading="lazy"
                                alt={selectedMedia?.title || "Large media"}
                                src={selectedMedia?.url || "large-blank-card.png"}
                                onClick={handleVideoClick}
                            />
                        )}
                        <div className={styles.smallCards}>
                            {mediaAssets.slice(0, 3).map((media, index) => (
                                <div
                                    key={index}
                                    className={index === 0 ? styles.smallVideoIconWrapper : styles.blankCardIconWrapper}
                                    onClick={() => handleMediaClick(media)}
                                    role="button"
                                    tabIndex={0}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            handleMediaClick(media);
                                        }
                                    }}
                                >
                                    <img
                                        className={index === 0 ? styles.smallVideoIcon : styles.blankCardIcon}
                                        loading="lazy"
                                        alt={`Thumbnail ${index}`}
                                        src={isVideo(media) ? thumbnails[media.url] || "placeholder-image.png" : media.url}
                                    />
                                    {isVideo(media) && (
                                        <div className={styles.playButtonWrapper}>
                                            <img src="/play-button.svg" alt="Play" className={styles.playButton} />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
}