import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout";
import Page from "./pages/index";
import Product from "./pages/product";
import Event from "./pages/event";
import Article from "./pages/article";
import Blog from "./pages/blog";
import BlogPost from "./pages/blog-post";
import Error from "./pages/error";
import "./styles/third-party.css";
import "./styles/style.css";
import "./styles/modal.css";
import "@contentstack/live-preview-utils/dist/main.css";
import "react-loading-skeleton/dist/skeleton.css";
import { EntryProps } from "./typescript/components";

function App() {
  const [getEntry, setEntry] = useState({} as EntryProps );

  function getPageRes(response: EntryProps) {
    setEntry(response);
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout entry={getEntry} />}>
            <Route index element={<Page entry={getPageRes} />} />
            <Route path="/:tier1/:tier2?/:tier3?/:tier4?" element={<Page entry={getPageRes} />} />
            <Route path="/product/:productId" element={<Product entry={getPageRes} />} />
            <Route path="/article/:articleId" element={<Article entry={getPageRes} />} />
            <Route path="/event/:eventId" element={<Event entry={getPageRes} />} />
            <Route path="/blog" element={<Blog entry={getPageRes} />} />
            <Route path="/blog/:blogId" element={<BlogPost entry={getPageRes} />} />
            <Route path="/404" element={<Error />}></Route>
            <Route path="*" element={<Error />}></Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
