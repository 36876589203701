import React, { useEffect, useState } from 'react';
import styles from '../styles/MegaNav.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { NavigationMenu, PageReference } from "../typescript/response";
import { Chevron } from './icon/chevron';
import Button from './button';
import LanguageSelector from './language-selector';
import { CSLocale } from '../typescript/components';

interface MegaNavProps {
    navMenu: NavigationMenu[], 
    isMobile?: boolean, 
    isOpenMobileSideBar?: boolean,
    handleCloseSideBar?: (arg: boolean) => void
    languages: CSLocale[] | null;
    currentLocale: string; // Add currentLocale as a prop
    onLocaleChange: (newLocale: string) => void; // Add onLocaleChange callback as a prop
    locations?: PageReference;

}
export default function MegaNav({ navMenu, isMobile = false, isOpenMobileSideBar = false, handleCloseSideBar, languages, currentLocale, onLocaleChange, locations }: MegaNavProps) {
    const [isMenuLevelTwoOpened, setIsMenuLevelTwoOpened] = useState(false)
    const [menuLevelTwo, setMenuLevelTwo] = useState<NavigationMenu | null>(null)
    const location = "/" + useLocation()?.pathname?.split('/')?.filter(p => p)?.[0];
    const selectMenu = (menu?: NavigationMenu) => {
      setIsMenuLevelTwoOpened(!isMenuLevelTwoOpened)
      if(menu)
        setMenuLevelTwo(menu)
    }

    const selectLink = () => {
      handleCloseSideBar && handleCloseSideBar(false); 
    }

    const getDropdownBanner = (nav: NavigationMenu) => {
      const {row_image: rowImage, column_image: colImage, label} = nav
      if(!rowImage && !colImage) return ''
   
      if (rowImage) {
        const remainder = nav.sub_navigation.length % 3;
        const blankItemsNeeded = remainder === 0 ? 2 : 2 - remainder
        const itemMissing = Array.from({ length: blankItemsNeeded }, (_, index) => (
          <div key={`blank-${index}`} className={styles.megaMenuL2}></div>
        ));
        return (
          <>
            {itemMissing}
            <div key={`${label}-banner`} className={styles.megaMenuL2}>
                <img src={rowImage.url} alt={rowImage.title} />
            </div>
          </>
          
        )
      } 
      if (colImage) {
        return (
          <>
            <div key={`${label}-banner`} className={`${styles.dropdownBannerCol} ${styles.megaMenuL2}`} 
                 style={{gridRow: `1 / span ${Math.ceil(nav.sub_navigation.length / 2) }`}}
            >
              <img src={colImage.url} alt={colImage.title} />
            </div>
          </>
        )
      }
    }   
    useEffect(() => {
      if(!isOpenMobileSideBar) 
        setIsMenuLevelTwoOpened(false); 
    }, [isOpenMobileSideBar])
  
    return (
        !isMobile ? 
        (
            <nav role="navigation" className={styles.links}>
                {navMenu && navMenu.length > 0 ? (
                    navMenu.map((list, listIndex) => (
                   
                        <div key={`${list.label}-${listIndex}`} className={styles.navbarLink}>
                            {
                                    (
                                        <NavLink
                                            {...list.$?.label as {}}
                                            to={list.page_reference[0].url}
                                            className={location === list.page_reference[0].url ? styles.mainLink + ' ' + styles.active : styles.mainLink}
                                        >
                                            {list.label}
                                        </NavLink>
                                    )
                            }
                            {list.sub_navigation && list.sub_navigation.length > 0 && (
                                <div className={styles.dropdown}>
                                    <div className={styles.megaMenu}>
                                        <NavLink className={styles.dropdownTitle} to={list.page_reference[0].url}>{list.label} <Chevron color='var(--primary-500)' direction='right' size={20} /></NavLink>
                                        <section className={styles.linkGroup}>
                                            <div className={styles.subLinks}>
                                                {list.sub_navigation.map((subItem, subIndex) => (
                                                    <div key={`${list.label}-${listIndex}-${subIndex}`} className={styles.megaMenuL2} style={{ gap: subItem?.title_image?.url ? 'var(--base)' : 'var(--xs)' }}>
                                                        <NavLink key={`${list.label}-${listIndex}-${subIndex}-link`} to={subItem.page_reference[0] && subItem.page_reference[0].url} className={styles.multiSlide}>
                                                            {subItem?.title_image?.url && <span><img className={styles.logoImageDropdown} src={subItem.title_image.url} alt="" />  <br /> <br /></span>}
                                                            {subItem.label}
                                                            <div key={`${list.label}-${listIndex}-${subIndex}-description`} className={styles.Menul2description}>{subItem.description}</div>
                                                        </NavLink>
                                                        
                                                    </div>
                                                ))}
                                                {getDropdownBanner(list)}
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <div className={styles.navbarLink}>
                        <a>
                            <Skeleton width={400} />
                        </a>
                    </div>
                )}
            </nav>
        ) 
        :
        (
            <>
                <div className={`${styles.navMobileContainer} ${isOpenMobileSideBar ? styles.open : ''}`}>
                <nav>
                    {navMenu && navMenu.length > 0 ? (
                        navMenu.map((list, listIndex) => (
                            <div key={`${list.label}-${listIndex}`} className={styles.navbarLink} onClick={() => {selectMenu(list)}}>
                                {
                                    (
                                            <>
                                            
                                            <div className={styles.menuItemWrapper}>
                                                <NavLink
                                                {...list.$?.label as {}}
                                                to={list.page_reference[0].url}
                                                className={ styles.menuMobileLink }
                                                onClick={selectLink}
                                                >
                                                    {list.label}
                                                </NavLink>
                                                {list?.sub_navigation?.length > 0 && <Chevron direction='right' onClick={() => {selectMenu(list)}}/>}
                                            </div>
                                            </>                                         
                                    )
                                }
                            </div>

                        ))
                    ) : (
                        <div className={styles.navbarLink}>
                            <a>
                                <Skeleton width={400} />
                            </a>
                        </div>
                    )}
                </nav>
                {
                <nav className={`${styles.navMobileContainerL2} ${isOpenMobileSideBar && isMenuLevelTwoOpened ? styles.open : ''}`}>
                    {menuLevelTwo && 
                    <div >
                              
                        <div className={`${styles.navbarLink} ${styles.navbarLinkTitleL2}`} onClick={()=> {setIsMenuLevelTwoOpened(false)}}>
                        <div className={styles.menuItemL2Wrapper}>
                        <Chevron color='var(--primary-500)' direction='left'/>
                            <NavLink  to={menuLevelTwo.page_reference?.[0]?.url} className={styles.multiSlide}>
                                {menuLevelTwo.label}
                            </NavLink>
                        </div>
                        </div>
                    </div>
                    }
                    { menuLevelTwo && (
                              
                        menuLevelTwo?.sub_navigation.map((list: any) => (
                            <div key={`${list.label}`} className={`${styles.navbarLink} ${styles.navbarLinkL2}`}>
                            <div className={styles.menuItemWrapper}>
                                <NavLink  to={list.page_reference?.[0]?.url} className={styles.multiSlide} onClick={selectLink}>
                                    {list.label}
                                    {list?.title_image?.url && <img className={styles.logoImageDropdown} src={list.title_image.url} alt="" />}
                                </NavLink>
                            </div>
                            </div>
                                  
                        )))
                    }
                </nav>
                        }
                        <div className={styles.mobleCTA}>
                            <div className={styles.mobileCTAButtons}>
                            <Button
                                text="Request a Quote"
                                showIcon
                                propWidth="11.25rem"
                                    propBackgroundColor="var(--white)"
                                    propHoverBackgroundColor="var(--charcoal-500)"
                                    propTextColor="var(--primary-500)"
                                link=""
                            />
                            <Button
                                text="Contact Us"
                                showIcon
                                propWidth="11.25rem"
                                propBackgroundColor="var(--primary-500)"
                                propHoverBackgroundColor="transparent"
                                propTextColor="var(--white)"
                                link=""
                                        />
                            </div>
                            <div className={styles.mobileBottomWrapper}>
                            {locations && locations.page_reference && locations.page_reference.length > 0 && locations.page_reference[0]?.url ? (
                                    <span className={styles.mobileLocationsLink}>
                                    <a href={locations.page_reference[0].url}>{locations.label}</a>
                                </span>
                            ) : null}
                                <LanguageSelector languages={languages} currentLocale={currentLocale} onLocaleChange={onLocaleChange}></LanguageSelector>
                            </div>
                        </div>
                 </div>
            </>
            
        )
        
    );
}
