import React from 'react';
import styles from '../styles/LargeCardGrid.module.css';
import { LargeCardGrid as LargeCardGridProp } from "../typescript/components";
import LargeCard from './large-card';

export default function LargeCardGrid({ large_card_grid }: { large_card_grid: LargeCardGridProp }) {
    const groupedCards = [];

    for (let i = 0; i < large_card_grid.large_cards.length; i += 2) {
        groupedCards.push(large_card_grid.large_cards.slice(i, i + 2));
    }

    return (
        <div className={styles.largeCardGrid}>
            {groupedCards.map((group, index) => (
                <section key={index} className={styles.row}>
                    {group.map((large_card, idx) => (
                        <LargeCard key={idx} large_card={large_card} />
                    ))}
                </section>
            ))}
        </div>
    );
}