import React from 'react';
import styles from '../styles/FeatureSection.module.css';
import { FeatureSection as FeatureSectionProp } from "../typescript/components";
import Button from "./button";

export default function FeatureSection({ feature_section }: { feature_section: FeatureSectionProp }) {

    return (
        <div className={styles.featureSection}>
            <div className={styles.engineeringTheFutureParent}>
                {feature_section.eyebrow && (
                    <b className={styles.engineeringTheFuture}>
                        {feature_section.eyebrow}
                    </b>
                )}
                <div className={styles.titleWrapper}>
                    <div className={styles.titleContainer}>
                        {feature_section.title && <span className={styles.title}>{feature_section.title}</span>}
                        {feature_section.title_alt && (
                            <span className={styles.titleAlt}>
                                {feature_section.title_alt}
                            </span>
                        )}
                    </div>
                </div>
                {feature_section.cta && feature_section.cta.cta_title ? (
                    <Button
                        text={feature_section.cta.cta_title}
                        showIcon
                        propWidth="250px"
                        propBackgroundColor="var(--primary-500)"
                        propHoverBackgroundColor="transparent"
                        propTextColor="var(--white)"
                        link={feature_section.cta.cta_link.url}
                    />
                ) : (
                ''
                )}
            </div>
            <div>
                {feature_section.image && feature_section.image.url && (
                    <img
                        className={styles.backgroundIcon}
                        loading="lazy"
                        alt={feature_section.image.title || "feature section image"}
                        src={feature_section.image.url}
                    />
                )}
                {feature_section.image_description && (
                    <div className={styles.imageDescription}>
                        {feature_section.image_description}
                    </div>
                    )}
            </div>
        </div>
    );
}
