import React from 'react';
import styles from '../styles/Snipe.module.css';
import { Snipe as SnipeProp } from "../typescript/components";
import Button from "./button";

export default function Snipe({ snipe }: { snipe: SnipeProp }) {
    return (
        <div className={styles.snipe}>
            <section className={styles.container}>
                <div className={styles.content}>
                    <span className={styles.title}>
                        {snipe.title}
                    </span>
                    <span className={styles.description}>
                        {snipe.description}
                    </span>
                </div>
                <Button
                    text={snipe.cta.cta_title}
                    showIcon
                    propWidth="250px"
                    propBorderColor="var(--white)"
                    propBackgroundColor="transparent"
                    propHoverBackgroundColor="var(--white)"
                    propHoverText="var(--charcoal-500)"
                    propTextColor="var(--white)"
                    link={snipe.cta.cta_link.url}
                />
            </section>

        </div>
    );
}

