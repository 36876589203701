import { Config, Region, LivePreview, Stack } from "contentstack";

const {
    REACT_APP_CONTENTSTACK_API_KEY,
    REACT_APP_CONTENTSTACK_DELIVERY_TOKEN,
    REACT_APP_CONTENTSTACK_ENVIRONMENT,
    REACT_APP_CONTENTSTACK_BRANCH,
    REACT_APP_CONTENTSTACK_REGION,
    REACT_APP_CONTENTSTACK_PREVIEW_TOKEN,
    REACT_APP_CONTENTSTACK_APP_HOST,
    REACT_APP_CONTENTSTACK_LIVE_PREVIEW,
    REACT_APP_CONTENTSTACK_PREVIEW_HOST,
    REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
    REACT_APP_CONTENTSTACK_BRAND
} = process.env;

// Basic environment validation
export const isBasicConfigValid = () => {
    return (
        !!REACT_APP_CONTENTSTACK_API_KEY &&
        !!REACT_APP_CONTENTSTACK_DELIVERY_TOKEN &&
        !!REACT_APP_CONTENTSTACK_ENVIRONMENT &&
        !!REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN &&
        !!REACT_APP_CONTENTSTACK_BRAND
    );
};

// Live preview config validation
export const isLpConfigValid = () => {
    return (
        !!REACT_APP_CONTENTSTACK_LIVE_PREVIEW &&
        !!REACT_APP_CONTENTSTACK_PREVIEW_TOKEN &&
        !!REACT_APP_CONTENTSTACK_PREVIEW_HOST &&
        !!REACT_APP_CONTENTSTACK_APP_HOST
    );
};

// Set region
const setRegion = (): Region => {
    let region = "US" as keyof typeof Region;
    if (!!REACT_APP_CONTENTSTACK_REGION && REACT_APP_CONTENTSTACK_REGION !== "us") {
        region = REACT_APP_CONTENTSTACK_REGION.toLocaleUpperCase().replace(
            "-",
            "_"
        ) as keyof typeof Region;
    }
    return Region[region];
};

// Set LivePreview config
const setLivePreviewConfig = (): LivePreview => {
    if (!isLpConfigValid())
        throw new Error("Your LP config is set to true. Please make sure you have set all required LP config in .env");
    return {
        preview_token: REACT_APP_CONTENTSTACK_PREVIEW_TOKEN as string,
        enable: REACT_APP_CONTENTSTACK_LIVE_PREVIEW === "true",
        host: REACT_APP_CONTENTSTACK_PREVIEW_HOST as string,
    } as LivePreview;
};

// Contentstack SDK initialization (for content delivery)
export const initializeContentStackSdk = (): Stack => {
    if (!isBasicConfigValid())
        throw new Error("Please set your .env file before running the starter app");

    const stackConfig: Config = {
        api_key: REACT_APP_CONTENTSTACK_API_KEY as string,
        delivery_token: REACT_APP_CONTENTSTACK_DELIVERY_TOKEN as string,
        environment: REACT_APP_CONTENTSTACK_ENVIRONMENT as string,
        region: setRegion(),
        branch: REACT_APP_CONTENTSTACK_BRANCH || "main"
    };

    if (REACT_APP_CONTENTSTACK_LIVE_PREVIEW === "true") {
        stackConfig.live_preview = setLivePreviewConfig();
    }

    if (REACT_APP_CONTENTSTACK_BRAND) {
        if (REACT_APP_CONTENTSTACK_BRAND == "form_technologies") {
            console.log("Brand Color Change to:", "form_technologies")
            document.documentElement.style.setProperty('--brand-primary-500', "#00A19B");
            document.documentElement.style.setProperty('--brand-primary-400', "#3B4AF");
            document.documentElement.style.setProperty('--brand-primary-300', "#66C7C3");
            document.documentElement.style.setProperty('--brand-primary-200', "#99D9D7");
            document.documentElement.style.setProperty('--brand-primary-100', "#CCECEB");
            document.documentElement.style.setProperty('--brand-primary-50', "#F2FAFA");
        }
        else if (REACT_APP_CONTENTSTACK_BRAND == "dynacast") {
            console.log("Brand Color Change to:", "dynacast")
            document.documentElement.style.setProperty('--brand-primary-500', "#2273BA");
            document.documentElement.style.setProperty('--brand-primary-400', "#4E8FC8");
            document.documentElement.style.setProperty('--brand-primary-300', "#7AABD6");
            document.documentElement.style.setProperty('--brand-primary-200', "#A7C7E3");
            document.documentElement.style.setProperty('--brand-primary-100', "#D3E3F1");
            document.documentElement.style.setProperty('--brand-primary-50', "#F4F8FC");
        }
        else if (REACT_APP_CONTENTSTACK_BRAND == "signicast") {
            console.log("Brand Color Change to:", "signicast")
            document.documentElement.style.setProperty('--brand-primary-500', "#D9272E");
            document.documentElement.style.setProperty('--brand-primary-400', "#E15258");
            document.documentElement.style.setProperty('--brand-primary-300', "#E87D82");
            document.documentElement.style.setProperty('--brand-primary-200', "#F0A9AB");
            document.documentElement.style.setProperty('--brand-primary-100', "#F7D4D5");
            document.documentElement.style.setProperty('--brand-primary-50', "#FDF4F5");
        }
        else if (REACT_APP_CONTENTSTACK_BRAND == "optimim") {
            console.log("Brand Color Change to:", "optimim")
            document.documentElement.style.setProperty('--brand-primary-500', "#5B5297");
            document.documentElement.style.setProperty('--brand-primary-400', "#7C75AC");
            document.documentElement.style.setProperty('--brand-primary-300', "#9D98C1");
            document.documentElement.style.setProperty('--brand-primary-200', "#BDBAD5");
            document.documentElement.style.setProperty('--brand-primary-100', "#DEDDEA");
            document.documentElement.style.setProperty('--brand-primary-50', "#F7F6FA");
        }
        else if (REACT_APP_CONTENTSTACK_BRAND == "techmire") {
            console.log("Brand Color Change to:", "techmire")
            document.documentElement.style.setProperty('--brand-primary-500', "#2273BA");
            document.documentElement.style.setProperty('--brand-primary-400', "#4E8FC8");
            document.documentElement.style.setProperty('--brand-primary-300', "#7AABD6");
            document.documentElement.style.setProperty('--brand-primary-200', "#A7C7E3");
            document.documentElement.style.setProperty('--brand-primary-100', "#D3E3F1");
            document.documentElement.style.setProperty('--brand-primary-50', "#F4F8FC");
        }
    }

    return Stack(stackConfig);
};

// API host URL
export const customHostUrl = (baseUrl = ''): string => {
    return baseUrl.replace("api", "cdn");
};

// Generate prod API URLs based on region
export const generateUrlBasedOnRegion = (): string[] => {
    return Object.keys(Region).map((region) => {
        if (region === "US") {
            return `cdn.contentstack.io`;
        }
        return `${region}-cdn.contentstack.com`;
    });
};

// Prod URL validation for custom host
export const isValidCustomHostUrl = (url: string): boolean => {
    return url ? !generateUrlBasedOnRegion().includes(url) : false;
};