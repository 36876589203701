import React from 'react';
import styles from '../styles/HeroBanner.module.css';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Button from "./button";
import { HeroBanner as HeroBannerProp } from "../typescript/components";

export default function HeroBanner({ hero_banner: banner }: { hero_banner: HeroBannerProp }) {
    const alignImageClass = banner.display_options.align_image === 'Left' ? styles.alignLeft : styles.alignRight;
    const alignTextClass =
        banner.display_options.align_text === 'Left' ? styles.textAlignLeft :
            banner.display_options.align_text === 'Right' ? styles.textAlignRight :
                styles.textAlignCenter; // default to center

    const containerStyle = {
        backgroundColor: banner.display_options.background_color || 'transparent',
    };

    const lightBackgroundClass = banner.display_options.background_color === 'Light' ? styles.lightBackground : '';

    const appendWidthParam = (url: string, width: number) => {
        try {
            const urlObj = new URL(url);
            urlObj.searchParams.set('width', width.toString());
            return urlObj.toString();
        } catch (error) {
            console.error("Invalid URL:", error);
            return url;
        }
    };

    return (
        <div className={`${styles.container} ${alignImageClass}`} style={containerStyle}>
            <div className={`${styles.heroSplit} ${lightBackgroundClass}`}>
                {banner.display_options.show_image && (
                    <div className={styles.imageContainer}>
                        {banner.banner_image ? (
                            <img className={styles.imageIcon}
                                loading="lazy"
                                {...banner.banner_image.$?.url as {}}
                                alt={banner.banner_image.filename}
                                src={appendWidthParam(banner.banner_image.url, 688)}
                            />
                        ) : (
                            <Skeleton className={styles.imageIcon}></Skeleton>        
                        )}
                    </div>
                )}
                <div className={`${styles.contentContainer} ${alignTextClass}`}>
                    <div className={`${styles.content} ${alignTextClass}`}>
                        <div className={styles.copy}>
                            <h1 className={styles.title} {...banner.$?.banner_title as {}}>
                                <span className={styles.titlePart1}>
                                    {banner.banner_title}
                                </span>
                                <span className={styles.titlePart2}>
                                    {banner.banner_title_alt}
                                </span>
                            </h1>
                            {banner.display_options.show_body_copy && (
                                <div className={styles.description}>
                                    {banner.banner_description}
                                </div>
                            )}
                        </div>
                        {banner.display_options.show_button && banner.call_to_action && (
                            <Button
                                text={banner.call_to_action.title}
                                showIcon
                                propWidth="250px"
                                propBackgroundColor="var(--primary-500)"
                                propHoverBackgroundColor="transparent"
                                propTextColor="var(--white)"
                                link={banner.call_to_action.href}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
