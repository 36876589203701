import React, { useState, CSSProperties } from 'react';
import styles from '../styles/Button.module.css'; // Ensure you have a separate CSS module for button styles

interface ButtonProps {
    text: string;
    link: string;
    showIcon?: boolean;
    propHoverText?: string;
    propFlex?: string;
    propAlignSelf?: string;
    propWidth?: string;
    propHeight?: string;
    propPadding?: string;
    propDisplay?: string;
    propMinWidth?: string;
    propHoverBackgroundColor?: string;
    propBackgroundColor?: string;
    propBorderColor?: string;
    propTextColor?: string;
}

const Button: React.FC<ButtonProps> = ({
    text,
    showIcon,
    propHoverText,
    propFlex,
    propAlignSelf,
    propWidth,
    propHeight,
    propPadding,
    propDisplay,
    propMinWidth,
    propHoverBackgroundColor,
    propBackgroundColor,
    propBorderColor,
    propTextColor,
    link
}) => {
    const [isHovered, setIsHovered] = useState(false);

    const buttonStyle: CSSProperties = {
        flex: propFlex,
        alignSelf: propAlignSelf,
        width: propWidth || '188px', // Default width
        height: propHeight || '48px', // Default width
        padding: propPadding,
        display: propDisplay,
        minWidth: propMinWidth,
        backgroundColor: isHovered ? propHoverBackgroundColor || 'var(--charcoal-500)' : propBackgroundColor || 'transparent',
        color: isHovered ? (propHoverText || 'var(--brand-primary-500)') : (propTextColor || 'var(--brand-primary-500)'), // Default text color
        border: '1px solid var(--primary-500)',
        borderRadius: 'var(--md)',
        boxSizing: 'border-box',
        cursor: 'pointer',
        transition: 'background-color 300ms ease-out, color 300ms ease-out, box-shadow 300ms ease-out',
        textAlign: 'center', // Center the text
        textDecoration: 'none', // Ensure no underline
        borderColor: propBorderColor || 'var(--primary-500)',
    };

    return (
        <a
            className={styles.button}
            style={buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            href={link}
        >
            {text}
            {showIcon && " >"}
        </a>
    );
};

export default Button;
