import React, { useState, useEffect } from 'react';
import styles from '../styles/LanguageSelector.module.css';
import { CSLocale } from '../typescript/components';

interface LanguageSelectorProps {
    languages: CSLocale[] | null;
    currentLocale: string;
    onLocaleChange: (newLocale: string) => void;
}

export default function LanguageSelector({ languages, currentLocale, onLocaleChange }: LanguageSelectorProps) {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial check for mobile

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const changeLocale = (newLocale: string) => {
        onLocaleChange(newLocale);
        setIsVisible(false); // Close the selector after selection
    };

    // Update isMobile state on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Early return if languages is null or empty
    if (!languages || languages.length === 0) {
        return null;
    }

    return (
        <div className={styles.languageLink}>
            <div className={styles.languageDropdown} onClick={toggleVisibility}>
                <img
                    className={styles.worldIcon}
                    loading="lazy"
                    alt="World Icon"
                    src="/world.svg"
                />
                <span className={styles.selectedLocale}>{currentLocale.toUpperCase()}</span>
                {isMobile ? (
                    <span className={styles.changeText}>Change</span> // Show "Change" on mobile
                ) : (
                    <img
                        className={styles.chevronDownIcon}
                        loading="lazy"
                        alt="Chevron Down Icon"
                        src="/chevron-down.svg"
                    /> // Show Chevron Down on desktop
                )}
            </div>
            <div className={`${styles.languageSelector} ${isVisible ? styles.visible : ''}`}>
                <div className={styles.title}>
                    <div className={styles.selectYourLanguage}>Select your language</div>
                    <img
                        className={styles.closeIcon}
                        alt="Close Icon"
                        src="/closex.svg"
                        onClick={toggleVisibility}
                    />
                </div>
                <div className={styles.navItemGroup}>
                    {languages.map((language) => (
                        <div
                            key={language.code}
                            className={styles.navItem}
                            onClick={() => changeLocale(language.code)}
                        >
                            <div className={styles.navLink}>{language.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
