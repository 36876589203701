import React from "react";

import Section from "./section";
import HeroBanner from "./hero-banner";
import BlogBanner from "./blog-banner";
import AboutBanner from "./about-banner";
import FeatureSection from "./feature-section";
import MapBanner from "./map-banner";
import ResourceGrid from "./resource-grid";
import Snipe from "./snipe";
import LogoBanner from "./logo-banner";
import CardSection from "./card-section";
import TeamSection from "./team-section";
import BlogSection from "./blog-section";
import VerticalCarousel from "./vertical-carousel";
import VerticalCard from "./vertical-card";
import SectionBucket from "./section-bucket";
import AboutSectionBucket from "./about-section-bucket";
import SectionWithHtmlCode from "./section-with-html-code";
import { ComponentsProps } from "../typescript/pages";
import ProductDisplayBanner from "./product-display-banner";
import ServicesTab from "./services-tab";
import CTABanner from "./cta-banner";
import LargeCardGrid from "./large-card-grid";
import LargeCard from "./large-card";
import SmallCardGrid from "./small-card-grid";
import SmallCard from "./small-card";
import EventSpotlightBanner from "./event-spotlight-banner";
import CTAOverlayBanner from "./cta-overlay-banner";
import LocationOverview from "./location-overview"

type RenderComponentsProps ={
  pageComponents:ComponentsProps[]
  blogsPage?: boolean
  contentTypeUid:string
  entryUid:string
  locale:string
}

export default function RenderComponents({ pageComponents, blogsPage, contentTypeUid, entryUid, locale }:RenderComponentsProps) {
      
  return (
    <div data-pageref={entryUid} data-contenttype={contentTypeUid} data-locale={locale}>
      {pageComponents?.map((component, key: number) => {
        if (component.hero_banner) {
          return blogsPage ? (
            <BlogBanner
              blog_banner={component.hero_banner}
              key={`component-${key}`}
            />
          ) : (
            <HeroBanner
              hero_banner={component.hero_banner}
              key={`component-${key}`}
            />
          );
        }
        if (component.section) {
          return (
            <Section section={component.section} key={`component-${key}`} />
          );
        }
        if (component.section_with_buckets) {
          return component.section_with_buckets.bucket_tabular ? (
            <AboutSectionBucket
              sectionWithBuckets={component.section_with_buckets}
              key={`component-${key}`}
            />
          ) : (
            <SectionBucket
              section={component.section_with_buckets}
              key={`component-${key}`}
            />
          );
        }
        if (component.from_blog) {
          return (
            <BlogSection blogs={component.from_blog} key={`component-${key}`} />
          );
        }
        if (component.section_with_cards) {
          return (
            <CardSection
              cards={component.section_with_cards.cards}
              key={`component-${key}`}
            />
          );
        }
        if (component.section_with_html_code) {
          return (
            <SectionWithHtmlCode
              embedObject={component.section_with_html_code}
              key={`component-${key}`}
            />
          );
        }
        if (component.our_team) {
          return (
            <TeamSection
              ourTeam={component.our_team}
              key={`component-${key}`}
            />
          );
        }
        if (component.logo_banner) {
            return (
                <LogoBanner
                    logoBanner={component.logo_banner}
                    key={`component-${key}`}
                />
            );
          }
          if (component.about_banner) {
              return (
                  <AboutBanner
                      about_banner={component.about_banner}
                      key={`component-${key}`}
                  />
              );
          }

          if (component.vertical_carousel) {
              return (
                  <VerticalCarousel
                      vertical_carousel={component.vertical_carousel}
                      key={`component-${key}`}
                  />
              );
          }

          if (component.vertical_card) {
              return (
                  <VerticalCard
                      vertical_card={component.vertical_card}
                      key={`component-${key}`}
                  />
              );
          }
          if (component.feature_section) {
              return (
                  <FeatureSection
                      feature_section={component.feature_section}
                      key={`component-${key}`}
                  />
              );
          }

          if (component.cta_banner) {
            return (
                <CTABanner
                    cta_banner={component.cta_banner}
                    key={`component-${key}`}
                />
            );
        }

        if (component.event_spotlight_banner) {
          return (
              <EventSpotlightBanner
                  event_spotlight_banner={component.event_spotlight_banner}
                  key={`component-${key}`}
              />
          );
       }

        if (component.cta_overlay_banner) {
          return (
              <CTAOverlayBanner
                  cta_overlay_banner={component.cta_overlay_banner}
                  key={`component-${key}`}
              />
          );
       }

          if (component.snipe) {
              return (
                  <Snipe
                      snipe={component.snipe}
                      key={`component-${key}`}
                  />
              );
          }
          if (component.map_banner) {
              return (
                  <MapBanner
                      map_banner={component.map_banner}
                      key={`component-${key}`}
                  />
              );
          }
          if (component.resource_grid) {
              return (
                  <ResourceGrid
                      resource_grid={component.resource_grid}
                      key={`component-${key}`}
                  />
              );
          }

          if (component.product_display_banner) {
              return (
                  <ProductDisplayBanner
                      product_display_banner={component.product_display_banner}
                      key={`component-${key}`}
                  />
              );
          }

          if (component.services_tab) {
              return (
                  <ServicesTab
                      services_tab={component.services_tab}
                      key={`component-${key}`}
                  />
              );
          }
          
          if (component.large_card_grid) {
              return (
                  <LargeCardGrid
                      large_card_grid={component.large_card_grid}
                      key={`component-${key}`}
                  />
              );
          }

          if (component.large_card) {
              return (
                  <LargeCard
                      large_card={component.large_card}
                      key={`component-${key}`}
                  />
              );
          }

          if (component.small_card_grid) {
              return (
                  <SmallCardGrid
                      small_card_grid={component.small_card_grid}
                      key={`component-${key}`}
                  />
              );
          }

          if (component.small_card) {
              return (
                  <SmallCard
                      small_card={component.small_card}
                      key={`component-${key}`}
                  />
              );
          }

          if (component.location_overview) {
              return (
                  <LocationOverview
                      location_overview={component.location_overview}
                      key={`component-${key}`}
                  />
              );

          }
      })}
    </div>
  );
}
