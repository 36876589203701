import React from 'react';
import styles from '../styles/AboutBanner.module.css';
import { AboutBanner as AboutBannerProp } from "../typescript/components";

export default function AboutBanner({ about_banner }: { about_banner: AboutBannerProp }) {
    const isVideo = (filename: string) => {
        return /\.(mp4|webm|ogg)$/i.test(filename);
    };

    const appendWidthParam = (url: string, width: number) => {
        try {
            const urlObj = new URL(url);
            urlObj.searchParams.set('width', width.toString());
            return urlObj.toString();
        } catch (error) {
            console.error("Invalid URL:", error);
            return url;
        }
    };

    return (
        <div className={styles.aboutBanner}>
            {about_banner.background && isVideo(about_banner.background.filename) ? (
                <video autoPlay muted loop className={styles.backgroundMedia}>
                    <source src={about_banner.background.url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            ) : (
                <img
                    src={appendWidthParam(about_banner.background?.url || '', 700)}
                    alt={about_banner.background?.title}
                    className={styles.backgroundMedia}
                />
            )}
            <section className={styles.content}>
                <div className={styles.titleWrap}>
                    {about_banner.title ? (
                        <div className={styles.title}>
                            {about_banner.title}
                        </div>
                    ) : (
                        ''
                    )}
                    {about_banner.title_alt ? (
                        <div className={styles.title_alt}>{about_banner.title_alt}</div>
                    ) : (
                        ''
                    )}
                </div>
                <div className={styles.bodyWrap}>
                    {about_banner.section_title ? (
                        <div className={styles.sectionTitle}>{about_banner.section_title}</div>
                    ) : (
                        ''
                    )}
                    {about_banner.section_copy ? (
                        <div className={styles.sectionCopy}>
                            {about_banner.section_copy}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </section>
        </div>
    );
}
