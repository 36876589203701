import React from 'react';
import VerticalCard from "./vertical-card";
import styles from "../styles/VerticalCarousel.module.css";
import { Link, NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import parse from 'html-react-parser';
import Skeleton from 'react-loading-skeleton';
import { VerticalCarousel as VerticalCarouselProp } from "../typescript/components";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper as SwiperType } from 'swiper'; // Import the Swiper type
import Button from "./button";

export default function VerticalCarousel({ vertical_carousel }: { vertical_carousel: VerticalCarouselProp }) {

  return (
      <div className={[styles.verticalCarousel].join(" ")}>
          <section className={styles.content}>
              <div className={styles.eyebrowWrapper}>
                  {vertical_carousel.eyebrow}
              </div>
              <div className={styles.titleWrapper}>
                  <span className={styles.title}>{vertical_carousel.title}</span>
                  <span className={styles.titleAlt}>{vertical_carousel.title_alt}</span>
              </div>
          </section>
          <section className={styles.cards}>
              <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  slidesPerView={"auto"}
                  loop={true}

                navigation={{
                    nextEl: `.${styles.nextButton}`,
                    prevEl: `.${styles.prevButton}`
                }}
                pagination={{
                    el: `.${styles.pagination}`,
                    clickable: true,
                }}
                   
              >
                  {vertical_carousel.vertical_cards.length > 0 ? (
                      vertical_carousel.vertical_cards.map((item, index) => (
                          <SwiperSlide key={index} className={styles.slide}>
                              {item ? (
                                  <VerticalCard vertical_card={ item }></VerticalCard>
                              ) : (
                                  " "
                              )}
                          </SwiperSlide>
                      ))
                  ) : (
                      Array.from({ length: 4 }).map((_, index) => (
                          <SwiperSlide key={index} className={styles.slide}>
                              <Skeleton count={1} className={styles.logo} />
                          </SwiperSlide>
                      ))
                  )}
              </Swiper>
          </section>
          <div className={[
              styles.navigation,
              vertical_carousel.display_options.center_cta ? styles.centeredNavigation : ""].join(" ")}>
              {vertical_carousel.cta ? (
                  <Button
                      text={vertical_carousel.cta.cta_title}
                      showIcon
                      propWidth="250px"
                      propBackgroundColor="var(--primary-500)"
                      propHoverBackgroundColor="transparent"
                      propTextColor="var(--white)"
                      link={vertical_carousel.cta.cta_title}
                  />
              ) : (
                  ''
              )}

              {vertical_carousel.display_options.show_navigation && (
                  <div className={styles.navigationWrapper}>
                      <button className={styles.prevButton}>
                          <img
                              className={styles.chevronLeftLarge}
                              loading="lazy"
                              alt=""
                              src="/chevron-left-large.svg"
                          />
                      </button>
                      <div className={styles.paginationButton}>
                          <div className={styles.pagination}>
                              <img
                                  className={styles.paginationDotIcon}
                                  loading="lazy"
                                  alt=""
                                  src="/pagination-dot.svg"
                              />
                          </div>
                      </div>
                      <button className={styles.nextButton}>
                          <img
                              className={styles.chevronRightLarge}
                              loading="lazy"
                              alt=""
                              src="/chevron-right-large.svg"
                          />
                      </button>
                  </div>
              )}
          </div>
      </div>
  );
}

