import React from 'react';
import styles from '../styles/ServicesTab.module.css';
import { ServicesTab as ServicesTabProp } from "../typescript/components";

export default function ServicesTab({ services_tab }: { services_tab: ServicesTabProp}) {

  return (
      <div className={styles.servicesTabs}>
          <section className={styles.title}>
              <div dangerouslySetInnerHTML={{ __html: services_tab.title }} />
          </section>
          <section className={styles.container}>
              <div className={styles.tabsVertical}>
                  <div className={styles.verticalTab}>
                    <div className={styles.tab}>Key benefit 1</div>
                  </div>
                  <div className={styles.verticalTab}>
                    <div className={styles.tab}>Key benefit 2</div>
                  </div>
                  <div className={styles.verticalTab}>
                    <div className={styles.tab}>Key benefit 3</div>
                  </div>
                  <div className={styles.verticalTab}>
                    <div className={styles.tab}>Key benefit 3</div>
                  </div>
              </div>
              <div className={styles.preview}>
                  <img
                      className={styles.imageIcon}
                      loading="lazy"
                      alt=""
                      src="/image@2x.png"
                  />
                  <div className={styles.copy}>
                      <div className={styles.benefitLabeledIn}>
                          Benefit labeled in 1-3 words
                      </div>
                      <div className={styles.briefDescriptionOf}>
                          Brief description of benefit or why/how you enable it
                      </div>
                  </div>
              </div>
          </section>
      </div>
  );
}