import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import RenderComponents from "../components/render-components";
import { getPageRes } from "../helper";
import Skeleton from "react-loading-skeleton";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { EntryProps } from "../typescript/components";
import { Page } from "../typescript/pages";

export default function Home({ entry }:{entry:({page, blogPost}:EntryProps)=> void}) {
    const lpTs = useLivePreviewCtx();
    const params = useParams();let entryUrl = "/";

    if (!params.tier1) {
        entryUrl = "/";
    } else if (params.tier1 && !params.tier2) {
        entryUrl = `/${params.tier1}`;
    } else if (params.tier1 && params.tier2) {
        entryUrl = `/${params.tier1}/${params.tier2}`;
    }
    if (params.tier3) entryUrl += `/${params.tier3}`;
    if (params.tier4) entryUrl += `/${params.tier4}`;


    const history = useNavigate();
    const [getEntries, setEntries] = useState({} as Page);
    const [error, setError] = useState(false);

  async function fetchData() {
    try {
      const result = await getPageRes(entryUrl);
      !result && setError(true);
      setEntries({ ...result });
      entry({ page: [result] });
    } catch (error) {
      setError(true);
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
    error && history("/404");
  }, [entryUrl, lpTs, error]);

  return Object.keys(getEntries).length ? (

    <RenderComponents
      pageComponents={getEntries?.page_components}
      contentTypeUid='page'
      entryUid={getEntries?.uid}
      locale={getEntries?.locale}
    />
  ) : (
    <Skeleton count={5} height={400} />
  );
}
