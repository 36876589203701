import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import RenderComponents from "../components/render-components";
import {getArticleRes} from "../helper";
import Skeleton from "react-loading-skeleton";
import {useLivePreviewCtx} from "../context/live-preview-context-provider";
import {ArticleRes} from "../typescript/pages";
import {EntryProps} from "../typescript/components";

export default function Article({ entry }: { entry: ({ page }: EntryProps) => void }) {
    const lpTs = useLivePreviewCtx();
    const { articleId } = useParams();
    const history = useNavigate();
    const [getEntry, setEntry] = useState({
        article: {} as ArticleRes
    });
    const [error, setError] = useState(false);

    async function fetchData() {
        try {
            const entryUrl = articleId ? `/article/${articleId}` : "/";
            const article = await getArticleRes(entryUrl);
            (!article) && setError(true);
            setEntry({ article });
            entry({ page: [article] });
        } catch (error) {
            console.error(error);
            setError(true);
        }
    }

    useEffect(() => {
        fetchData();
        error && history("/404");
    }, [articleId, lpTs, error]);

    const { article } = getEntry;
  return (
      <>
          {article ? (
              <RenderComponents
                  pageComponents={article.page_components}
                  blogsPage
                  contentTypeUid='article'
                  entryUid={article.uid}
                  locale={article.locale}
              />
          ) : (
              <Skeleton height={400} />
          )}

      </>
  );
}
