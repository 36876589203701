import React from 'react';
import styles from '../styles/LargeCard.module.css';
import { LargeCard as LargeCardProp } from "../typescript/components";
import Button from './button';

export default function LargeCard({ large_card }: { large_card: LargeCardProp }) {
    const hasBackgroundImage = large_card.background && large_card.background.url !== undefined;
    const backgroundStyle = (large_card.background && large_card.background.url)
        ? { backgroundImage: `url(${large_card.background.url})` }
        : {};

    return (
        <div
            className={`${styles.largeSquareCard} ${hasBackgroundImage ? styles.withBackground : ''}`}
            style={backgroundStyle}
        >
            <div className={styles.content}>
                <h3 className={styles.title}>{large_card.title}</h3>
                <div className={styles.description}>{large_card.description}</div>
                <Button
                    text={large_card.cta.cta_title}
                    link={large_card.cta.cta_link.url}
                    showIcon
                    propWidth="250px"
                    propBackgroundColor="var(--primary-500)"
                    propHoverBackgroundColor="white"
                    propTextColor="var(--white)"
                />
            </div>
        </div>
    );
}
