import React from 'react';
import styles from '../styles/SmallCard.module.css';
import { SmallCard as SmallCardProp } from "../typescript/components";

export default function SmallCard({ small_card }: { small_card: SmallCardProp }) {

    const hasBackgroundImage = small_card.background && small_card.background.url !== undefined;
    const backgroundStyle = (small_card.background && small_card.background.url)
        ? { backgroundImage: `url(${small_card.background.url})` }
        : {};

    return (
        <div className={`${styles.cardContainer} ${hasBackgroundImage ? styles.withBackground : styles.withOutBackground}`} style={backgroundStyle}>
            <div className={styles.title}>{small_card.title}</div>
            <div className={styles.smallSquareCard}>
                <div className={styles.title2}>{small_card.title}</div>
                <div className={styles.description}>
                    {small_card.description}
                </div>
                <a className={styles.link}>{small_card.cta.cta_title}</a>
            </div>
        </div>
    );
}
